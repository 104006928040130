import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoISectoresTable = props => {
  const [ciius, setCiius] = useState([]);
  const [sectores, setSectores] = useState();

  useEffect(() => {
    ReferenciasApi.GetTabla("PUBLICACIONES.CIIU").then(response => { setCiius(response); });
    setSectores(props.Sectores)
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdSector', key: 'IdSector' },
        { title: 'Nombre', dataIndex: 'Nombre', key: 'Nombre' },
        { title: 'Ciiu', dataIndex: 'Ciiu', key: 'Ciiu',
          render: text => Utils.getIdDesc(text, ciius) 
        },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>  
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdSector ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Sectores }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoISectoresTable;