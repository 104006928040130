import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import logo from '../../assets/logo-art-big.png';
import logoCollapsed from '../../assets/logo-cpa2.png';
import './AppLeftMenu.scss';
import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import UsuariosApi from '../../../usuarios/api/UsuariosApi';
import { DatabaseOutlined, LaptopOutlined, UserOutlined, QuestionOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const AppLeftMenu = () => {
  const [collapsed, onCollapse] = useState(false);
  const [tipoUsuario, setTipoUsuario] = useState();

  useEffect(() => {
    UsuariosApi.TipoUsuario(AuthenticationHelper.getId().toString())
      .then(response => setTipoUsuario(response));
  }, []);

  const getMenuItems = () => {
    if (tipoUsuario !== "Operador") {
      return [
        { key: '1', icon: <LaptopOutlined />, label: <Link to="/presentaciones">Presentaciones</Link> },
        { key: '2', icon: <DatabaseOutlined />, label: <Link to="/referencias">Referencias</Link> },
        { key: '3', icon: <UserOutlined />, label: <Link to="/auth/ticket/usuarios">Usuarios</Link> },
        { key: '4', icon: <QuestionOutlined />, label: <Link to="/ayuda">Ayuda</Link> }
      ];
    } else {
      return [
        { key: '1', icon: <LaptopOutlined />, label: <Link to="/presentaciones">Presentaciones</Link> },
        { key: '2', icon: <DatabaseOutlined />, label: <Link to="/referencias">Referencias</Link> },
        { key: '3', icon: <QuestionOutlined />, label: <Link to="/ayuda">Ayuda</Link> }
      ];
    }
  };

  return (
    <Sider className="left-menu-main" collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="app-layout-logo">
        {collapsed
          ? <img src={logoCollapsed} alt="logo" className="logoImg" style={{ width: 64 }} />
          : <img src={logo} alt="logo" className="logoImg" style={{ width: 150, height: 70 }} />}
      </div>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" items={getMenuItems()} />
    </Sider>
  );
};

export default AppLeftMenu;
