import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Tabs, List, Input, Spin, Card, Table } from "antd";
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import PresentacionesApi from '../../api/PresentacionesApi';
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const TabPane = Tabs.TabPane;

const PresentacionList = () => {
  const history = useHistory();
  const [presentaciones, setPresentaciones] = useState([]);
  const [filteredPresentaciones, setFilteredPresentaciones] = useState([]);
  const [working, setWorking] = useState(false);
  let idUsuario = AuthenticationHelper.getId().toString();
  let tipoUsuario = AuthenticationHelper.getTipoUsuario();

  useEffect(() => {
    loadPresentaciones();
  }, []);

  const loadPresentaciones = () => {
    setWorking(true);
    PresentacionesApi.GetPresentacionesUsuario(idUsuario)
      .then((response) => {
        setWorking(false);
        if (response.length === 1) {
          AuthenticationHelper.storeIdPresentacionUsuario(response[0].Id);
        }
        AuthenticationHelper.storePresentacionUsuario(response);
        setPresentaciones(response);
        setFilteredPresentaciones(response);
      })
      .catch((error) => { setWorking(false); });
  }

  const iniciarPresentacion = () => {
    if (tipoUsuario !== "Operador") {
      return (
        <Button key="1" type="primary" icon={<PlusOutlined />}
          onClick={() => history.push(history.location.pathname + '/new')}
        >
          Iniciar
        </Button>
      );
    }
  }

  const handleSearch = e => {
    if (e.target.value.length > 0) {
      setFilteredPresentaciones(presentaciones.filter(x => x.Id.toString().indexOf(e.target.value) > -1 || x.Cuit.indexOf(e.target.value) > -1));
    }
    else {
      setFilteredPresentaciones(presentaciones);
    }
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title="Presentaciones"
        extra={iniciarPresentacion()}
      />

      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>
          <Card>
            <Input
              allowClear
              placeholder="Buscar"
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              style={{ marginBottom: 8 }}
            />

            <Table
              style={{ cursor: 'pointer' }}
              size="small"
              columns={[
                { title: 'Presentación', dataIndex: 'Id', key: 'Id' },
                { title: 'Cuit', dataIndex: 'Cuit', key: 'Cuit' },
                { title: 'Empleador', dataIndex: 'Empleador', key: 'Empleador' }
              ]}
              dataSource={filteredPresentaciones}
              rowKey={(record) => record.Id}
              pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
              onRow={record => ({
                onClick: () => { 
                  AuthenticationHelper.storeEmpleador(record.Empleador);
                  history.push(history.location.pathname + '/view/' + record.Id + '/' + record.Cuit); 
                }
              })}
            />
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default PresentacionList;
