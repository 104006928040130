import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import DeleteButton from "../../../../../../shared/components/deleteButton";
import EditButton from "../../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../../shared/helpers/utils";

const AnexoVMedidasPreventivasDelPuestoTable = props => {
  const [medidasPreventivas, setMedidasPreventivas] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TMEDIDASPREVENTIVAS").then(response => { setMedidasPreventivas(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdMedidaPreventiva', key: 'IdMedidaPreventiva' },
        { title: 'Referencial de Medidas Preventivas', dataIndex: 'IdReferencialDeMedidasPreventivas', key: 'IdReferencialDeMedidasPreventivas',
          render: text => Utils.getIdDesc(text, medidasPreventivas)
        },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdMedidaPreventiva ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.MedidasPreventivasDelPuesto }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVMedidasPreventivasDelPuestoTable;