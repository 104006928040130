import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoVEstudiosBiologicosEspecificosTable = props => {
  const [unidadesFrecuencia, setUnidadesFrecuencia] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TFRECUENCIA").then(response => { setUnidadesFrecuencia(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdEstudioBiologicoEspecifico', key: 'IdEstudioBiologicoEspecifico' },
        //{ title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        { title: 'Cantidad Frecuencia', dataIndex: 'CantidadFrecuencia', key: 'CantidadFrecuencia' },
        { title: 'Unidad Frecuencia', dataIndex: 'IdUnidadFrecuencia', key: 'IdUnidadFrecuencia',
          render: text => Utils.getIdDesc(text, unidadesFrecuencia)
        },
        { title: 'Analisis Estudios Complementacion', dataIndex: 'AnalisisEstudiosComplementacion', key: 'AnalisisEstudiosComplementacion' },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdEstudioBiologicoEspecifico ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.EstudiosBiologicosEspecificos }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVEstudiosBiologicosEspecificosTable;