import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Form, Card, Input, Spin, Collapse, Tooltip, notification, Modal, Space } from "antd";
import { SaveOutlined, PlusCircleTwoTone, ExclamationCircleOutlined, FileExcelTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import NominasApi from "../../api/NominasApi";
import NominaTrabajadoresTable from '../view/NominaTrabajadoresTable';
import NominaTrabajadorModal from "./NominaTrabajadorModal";
import NominaUplodaModal from "./NominaUploadModal";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Panel } = Collapse;

const NominaForm = props => {
  const history = useHistory();
  const [trabajadores, setTrabajadores] = useState([]);
  const [showTrabajadorModal, setShowTrabajadorModal] = useState(false);
  const [selectedTrabajador, setSelectedTrabajador] = useState(undefined);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const idsEst = JSON.parse(AuthenticationHelper.getIdsEstablecimientosUsuario(AuthenticationHelper.getId()));
  const tipoUsuario = AuthenticationHelper.getTipoUsuario().toString();
  let trabajadoresUsuario = [];
  let sustanciasCliente = [];
  let AnexoV = props.location.anexov;

  useEffect(() => {
    if(idsEst!==null){
      idsEst.forEach(id => {
        AnexoV.Sustancias.forEach( sustancia => {
          if(sustancia.IdEstablecimientoEmpresa == id) {
            sustanciasCliente.push(sustancia);
          }
        });
  
        AnexoV.Sustancias.splice(0);
        sustanciasCliente.forEach(sustancia => {
          AnexoV.Sustancias.push(sustancia);
        });
      });
    }

    if (props.location.pathname.indexOf('edit') > -1 && !props.location.anexoi && !props.location.anexov && !props.location.nomina) {
      history.goBack();
    }
    if (props.location.pathname.indexOf('new') > -1 && !props.location.anexoi && !props.location.anexov) {
      history.goBack();
    }
    if (props.location.nomina) {
      if (tipoUsuario == "Operador") {
        let totalTrabajadores = props.location.nomina.Trabajadores;
        idsEst.forEach(itemId => {
            totalTrabajadores.forEach(trabajador => {
              if(trabajador.IdEstablecimientoEmpresa == itemId){
                trabajadoresUsuario.push(trabajador);
              }
          });
        });
        setTrabajadores(trabajadoresUsuario);
      } else {
        setTrabajadores(props.location.nomina.Trabajadores);
      }
    }
  }, []);

  const onFinish = values => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>¿Está seguro de querer guardar la Nomina?</span>,
      onOk() {
        setWorking(true);
        let data = {
          //id: props.match.params.id, 
          ...values,
          Trabajadores: trabajadores
        }
        if (!props.location.nomina) {
          NominasApi.Save(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
        else {
          NominasApi.Edit(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
      },
      onCancel() { },
    });

    const then = response => {
      setWorking(false);
      if (response == 'ok') {
        notification.success({ message: 'Exito', description: 'Nomina guardada', placement: 'bottomRight' });
        history.goBack();
      } else {
        notification.error({ message: 'Error', description: '¡No se pudo guardar la Nomina!', placement: 'bottomRight' });
        notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
      }
    }
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title={`${props.location.nomina ? '' : 'Nueva'} Nomina`}
        //subTitle="Formulario"
        onBack={() => history.goBack()}
      />
      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>
          <Card>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              onFinish={onFinish}
              initialValues={{ 
                ...props.location.nomina,
                Cuit: props.location.nomina?.Cuit || '' // Manera de prevenir el conflicto sobreescrito de inicialValues
              }}
              scrollToFirstError
            >
              <FormItem name="Cuit" label="Cuit" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem>

              {props.location.nomina?.IdTipoOrganismo ? <FormItem name="IdTipoOrganismo" label="Tipo Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.location.nomina?.IdOrganismo ? <FormItem name="IdOrganismo" label="Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}


              <Collapse style={{ marginBottom: 24 }}>

                <Panel header="Trabajadores" key="1"
                  extra={props.location.puedeEditar == true ?
                    <Space>
                      <Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                        onClick={event => {
                          event.stopPropagation();
                          setSelectedTrabajador(undefined);
                          setShowTrabajadorModal(true);
                        }}
                      /></Tooltip>
                      <Tooltip title="Importar desde archivo"><FileExcelTwoTone twoToneColor="#159E53"
                        onClick={event => { event.stopPropagation(); setShowUploadModal(true); }}
                      /></Tooltip>
                    </Space>
                    : null
                  }
                >
                  {trabajadores.length > 0
                    ? <NominaTrabajadoresTable formMode
                      cuit={props.match.params.cuit}
                      anexoi={props.location.anexoi}
                      anexov={AnexoV}
                      puedeEditar={props.location.puedeEditar}
                      Trabajadores={trabajadores}
                      onRemove={record => { setTrabajadores([...trabajadores.filter(item => (item.Hash !== record.Hash))]); }}
                      onEdit={record => {
                        setSelectedTrabajador(record);
                        setShowTrabajadorModal(true);
                      }}
                    />
                    : ''}
                </Panel>
              </Collapse>

              <FormItem wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
              </FormItem>
            </Form>

            {
              showTrabajadorModal ?
                <>
                  <NominaTrabajadorModal
                    cuit={props.match.params.cuit}
                    trabajador={selectedTrabajador}
                    anexoi={props.location.anexoi}
                    anexov={AnexoV}
                    visible={showTrabajadorModal}
                    onOk={trabajador => {
                      let arr = [...trabajadores];
                      let idx = arr.findIndex(x => x.Hash ? x.Hash === trabajador.Hash : x.IdTrabajador === trabajador.IdTrabajador);
                      if (idx !== -1) { arr[idx] = trabajador; }
                      else { arr.push(trabajador); }
                      setTrabajadores([...arr]);
                      setShowTrabajadorModal(false);
                    }}
                    onCancel={() => setShowTrabajadorModal(false)}
                  />
                </> : null
            }

            {
              showUploadModal ?
                <>
                  <NominaUplodaModal
                    visible={showUploadModal}
                    onCancel={() => setShowUploadModal(false)}
                    importCSV={response => {
                      setShowUploadModal(false);
                      setTrabajadores([...trabajadores, ...response.Trabajadores]);
                    }}
                  />
                </> : null
            }

          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default NominaForm;