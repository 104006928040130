import React, { useEffect, useState } from 'react';
import { Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import parser from "../../shared/helpers/parser";
import imgLogo from "../login/assets/logo-art-big.jpg";
import Utils from "../../shared/helpers/utils";
import AuthenticationHelper from "../../shared/helpers/authenticationHelper";
import UsuariosApi from "../usuarios/api/UsuariosApi";

const GeneratePDF = ({ presentacion, numMuestra, estados }) => {
  const [usuario, setUsuario] = useState(null);
  const [empleador, setEmpleador] = useState(null);

  useEffect(() => {
    UsuariosApi.Get(AuthenticationHelper.getId()).then(response => { setUsuario(response);});
    setEmpleador(AuthenticationHelper.getEmpleador().replaceAll('"', ''));
  }, []);


  const downloadPDF = () => {
    const today = new Date();
    const formattedDate = today.toLocaleString(undefined, {
      hour12: false,
    });

    const img = new Image();
    img.src = imgLogo;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      const documentDefinition = {
        content: [
          {
            columns: [
              { image: dataURL, width: 130 },
              { 
                text: "COMPROBANTE DE PRESENTACIÓN",
                bold: true,
                alignment: "right",
                fontSize: 18,
                margin: [0, 20, 0, 0]
              }
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 20,
                x2: 495,
                y2: 20,
                lineWidth: 0.5
              }
            ]
          },
          {
            alignment: "center",
            columns: [
              {
                text: "\nEmpleador: " + empleador,
                bold: true,
                alignment: "left",
                fontSize: 14,
              },
              {
                text: "\nCUIT Presentación: " + presentacion.Cuit,
                bold: true,
                alignment: "right",
                fontSize: 14,
              },
            ],
          },
          {
            text: "\nID Presentación: " + presentacion?.PresentacionId,
            alignment: "left", 
            margin: [0, 20, 0, 0]
          },
          {
            text: "\nNúmero de Poliza: " + presentacion?.NumeroDePoliza,
            alignment: "left", 
          },
          {
            text: "\nPrograma Muestra: " + numMuestra,
            alignment: "left", 
          },
          {
            text: "\nFecha de presentación: " + parser.date(presentacion?.FechaPresentacionEmpleador),
            alignment: "left",
          },
          {
            text: "\nFecha de consolidación: " + parser.datetime(presentacion?.FechaDeConsolidacion),
            alignment: "left",
          },
          {
            text: "\nObservaciones: " + presentacion?.Observaciones,
            alignment: "left",
            margin: [0, 0, 0, 20]
          },
          {
            text: "\nESTADOS DE PRESENTACIÓN",
            alignment: "center",
          },
          presentacion?.SeguimientoEstados?.sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha))
          .map(estado => (
            {
              alignment: "center",
              columns: [
                {
                  text: "\nEstado: " + Utils.getIdDesc(estado.IdEstado, estados).replace(/([A-Z])/g, ' $1').trim(),
                  alignment: "left",
                  margin: [20, 0, 0, 0]
                },
                {
                  text: "\nFecha: " + parser.datetime(estado.Fecha),
                  alignment: "right",
                  margin: [0, 0, 20, 0]
                },
              ],
            }
          )),
        ],
        footer: (currentPage, pageCount) => {
          return {
            text: "Impreso por: " + usuario.Correo + " | " + formattedDate,
            alignment: "right",
            fontSize: 8,
            margin: [0, 0, 20, 0],
          };
        },
        styles: {
          subheader: {
            fontSize: 14,
            bold: false,
            margin: [0, 15, 0, 0],
          },
          text: {
            fontSize: 13,
            bold: false,
          },
        },
        pageMargins: [50, 70, 50, 20],
      };

      pdfMake
        .createPdf(documentDefinition)
        .download(`comprobante_${presentacion.Cuit}.pdf`);
    };
  };

  return (
    <Button onClick={downloadPDF} key="1" type="primary" icon={<FilePdfOutlined />}>
      Descargar Comprobante
    </Button>
  );
};

export default GeneratePDF;
