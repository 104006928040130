import jwtDecode from 'jwt-decode';

/* eslint class-methods-use-this: 0 */

class AuthenticationHelper {

  static storeIdTipoUsuario(Id) {
    sessionStorage.setItem("IdTipoUsuario", Id);
    return null;
  }

  static getIdTipoUsuario() {
    return sessionStorage.getItem('IdTipoUsuario');
  }

  static removeIdTipoUsuario() {
    sessionStorage.removeItem("IdTipoUsuario");
    return null;
  } 


  static storeTipoUsuario(Id) {
    sessionStorage.setItem("TipoUsuario", Id);
    return null;
  }

  static getTipoUsuario() {
    return sessionStorage.getItem('TipoUsuario');
  }


  static storeId(Id) {
    sessionStorage.setItem("Id", Id);
    return null;
  }

  static getId() {
    return sessionStorage.getItem('Id');
  }


  static storeUsuarioSesion(Usuario) {
    sessionStorage.setItem("UsuarioSesion", JSON.stringify(Usuario));
    return null;
  }
  static getUsuarioSesion() {
    return sessionStorage.getItem('UsuarioSesion');
  }


  static storeIdsEstablecimientosUsuario(IdsEstablecimientosUsuario) {
    sessionStorage.setItem('IdsEstablecimientosUsuario', JSON.stringify(IdsEstablecimientosUsuario));
    return null;
  }
  
  static getIdsEstablecimientosUsuario() {
    return sessionStorage.getItem('IdsEstablecimientosUsuario');
  }

  static removeIdsEstablecimientosUsuario() {
    sessionStorage.removeItem("IdsEstablecimientosUsuario");
    return null;
  } 


  static storePresentacionSeleccionada(presentacion) {
    sessionStorage.setItem("PresentacionSeleccionada", JSON.stringify(presentacion));
    return null;
  }

  static getPresentacionSeleccionada() {
    return sessionStorage.getItem('PresentacionSeleccionada');
  }

  static removePresentacionSeleccionada() {
    sessionStorage.removeItem("PresentacionSeleccionada");
    return null;
  }


  static storeIdTipoPresentacion(Id) {
    sessionStorage.setItem("IdTipoPresentacion", Id);
    return null;
  }

  static getIdTipoPresentacion() {
    return sessionStorage.getItem('IdTipoPresentacion');
  }

  static removeIdTipoPresentacion() {
    sessionStorage.removeItem("IdTipoPresentacion");
    return null;
  } 
  

  static storeEmpleador(Empleador) {
    sessionStorage.setItem("Empleador", JSON.stringify(Empleador));
    return null;
  }
  static getEmpleador() {
    return sessionStorage.getItem('Empleador');
  }


  static storePresentacionUsuario(PresentacionUsuario) {
    sessionStorage.setItem("PresentacionUsuario", JSON.stringify(PresentacionUsuario));
    return null;
  }
  static getPresentacionUsuario() {
    return sessionStorage.getItem('PresentacionUsuario');
  }
  static storeIdPresentacionUsuario(CuitPresentacionUsuario) {
    sessionStorage.setItem("IdPresentacionUsuario", CuitPresentacionUsuario);
    return null;
  }
  static getIdPresentacionUsuario() {
    return sessionStorage.getItem('IdPresentacionUsuario');
  }

  static removeIdPresentacionUsuario() {
    sessionStorage.removeItem("IdPresentacionUsuario");
    return null;
  } 


  static storeCuitPresentacionUsuario(CuitPresentacionUsuario) {
    sessionStorage.setItem("CuitPresentacionUsuario", CuitPresentacionUsuario);
    return null;
  }

  static getCuitPresentacionUsuario() {
    return sessionStorage.getItem('CuitPresentacionUsuario');
  }


  static storeDatosRemove(datos) {
    sessionStorage.setItem("DatosRemove", JSON.stringify(datos));
    return null;
  }

  static getDatosRemove() {
    return JSON.parse(sessionStorage.getItem('DatosRemove'));
  }

  static removeDatosRemove() {
    sessionStorage.removeItem("DatosRemove");
    return null;
  } 






  static logout = (callback = () => {}) => {
    //AuthenticationHelper.removeSRTTicket();
    sessionStorage.clear();
    callback();
  };

  static removeSRTTicket() {
    sessionStorage.removeItem("SRTticket");
    return null;
  }

  static storeSRTTicket(SRTticket) {
    sessionStorage.setItem("SRTticket", SRTticket);
    return null;
  }

  static storeSRTWS(SRTWS) {
    sessionStorage.setItem("SRTWS", SRTWS);
    return null;
  }

  static isSRTTicketStored() {
    return sessionStorage.SRTticket ? true : false;
  }

  static getSRTTicket() {
    return AuthenticationHelper.isSRTTicketStored() ? sessionStorage.SRTticket : null;
  }










    /*static isUserAuthenticated() {
    return sessionStorage.jwtToken ? true : false;
  }

  static logout = (callback = () => {}) => {
    AuthenticationHelper.removeJwtToken();
    sessionStorage.removeItem("userPermissions");
    callback();
  };

  static isJwtTokenStored() {
    return sessionStorage.jwtToken ? true : false;
  }

  static getJwtToken() {
    return AuthenticationHelper.isJwtTokenStored() ? sessionStorage.jwtToken : null;
  }

  static storeJwtToken(jwtToken) {
    if (jwtToken.indexOf("Bearer ") >= 0) {
      jwtToken = jwtToken.replace("Bearer ", "");
    }
    sessionStorage.setItem("jwtToken", jwtToken);
    return null;
  }

  static removeJwtToken() {
    sessionStorage.removeItem("jwtToken");
    return null;
  }

  static hasJwtPayloadKey(key) {
    if (AuthenticationHelper.isJwtTokenStored()) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key] !== undefined;
    }
    return null;
  }

  static getValueFromJwtPayload(key) {
    if (AuthenticationHelper.isJwtTokenStored) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key];
    }
    return null;
  }

  static getDecodedToken() {
    return AuthenticationHelper.isJwtTokenStored ? jwtDecode(sessionStorage.jwtToken) : null;
  }

  static storeUser(user) {
    sessionStorage.setItem("user", user);
    return null;
  }

  static getUser() {
    return sessionStorage.user ? sessionStorage.user : '';
  }

  static removeUser() {
    sessionStorage.removeItem("user");
    return null;
  }*/
}
export default AuthenticationHelper;
