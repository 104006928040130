import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoVUtilizacionesTable = props => {
  const [usosOrigen, setUsosOrigen] = useState([]);
  const [modosEmpleo, setModosEmpleo] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TUSO_ORIGEN").then(response => { setUsosOrigen(response); });
    ReferenciasApi.GetTabla("SVCC.TMODO_EMPLEO").then(response => { setModosEmpleo(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdUtilizacion', key: 'IdUtilizacion' },
        //{ title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        { title: 'Uso Origen', dataIndex: 'UsoOrigen', key: 'UsoOrigen',
          render: text => Utils.getIdDesc(text, usosOrigen)
        },
        { title: 'Modo de Empleo', dataIndex: 'ModoDeEmpleo', key: 'ModoDeEmpleo',
          render: text => Utils.getIdDesc(text, modosEmpleo)
        },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdUtilizacion ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Utilizaciones }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVUtilizacionesTable;