import React, {  useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, Button, notification } from "antd";
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import AuthApi from "../../../auth/api/AuthApi";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import Usuario_EstablecimientoApi from "../../../usuario_establecimiento/api/Usuario_EstablecimientoApi";
import UsuariosApi from "../../../usuarios/api/UsuariosApi";
import RecuperarClaveModal from "../../../email/components/form/RecuperarClaveModal";
import LoginApi from "../../api/LoginApi";
import pdfNotaExceptuados from "../../ART-Respuesta-cliente-no-alcanzado-Res-81.docx";


const FormItem = Form.Item;

const LoginForm = () => {
  const history = useHistory();
  const [working, setWorking] = useState(false);

  const onFinish = (values) => {
    setWorking(true);
    AuthApi.Encrypt(values.password)
    .then(responsePSW => {
      let password = responsePSW.password;
      LoginApi.Login(values.email, password)
      .then(responseLogin => {
        setWorking(false);
        if (responseLogin !== 0) {
          AuthenticationHelper.storeId(responseLogin);
          UsuariosApi.TipoUsuario(responseLogin).then(response1 => { 
            AuthenticationHelper.storeTipoUsuario(response1);
            if(response1=="Operador"){
              Usuario_EstablecimientoApi.GetIdsEstablecimientosUsuario(responseLogin).then( response => { AuthenticationHelper.storeIdsEstablecimientosUsuario(response); });
            }
          }); 
          AuthApi.GetTicket('WSE')
            .then((response) => {
              setWorking(false);
              history.push("/");
            })
            .catch((error) => { setWorking(false); });
        } else {
          setWorking(false);
          notification.error({ message: 'Error', description: 'Usuario o Clave incorrectos', placement: 'bottomRight' });
        }
      })
      .catch(error => {
        setWorking(false);
        console.error("fallo");
        throw error;
      });
    })
    .catch((error) => { setWorking(false); });
  }


  return (
    <Form
      {...FormConfig.DefaultLayout}
      name="basic"
      initialValues={{ remember: true }}
      labelAlign="left"
      onFinish={onFinish}
    >
      <FormItem label="Email" name="email"
        rules={[
          { required: true, message: "Ingresa tu usuario por favor." },
          { type: 'email', message: 'Por favor ingrese un email válido' }
        ]}
      >
        <Input/>
      </FormItem>

      <FormItem label="Clave" name="password"
        rules={[{ required: true, message: "La clave es requerida." }]}
      >
        <Input.Password />
      </FormItem>
      <div>
      <RecuperarClaveModal/>
      </div>

      <FormItem {...{ wrapperCol: { span: 24 } }}>
        <Button block type="primary" htmlType="submit" loading={working}>Ingresar</Button>
      </FormItem>
      <p><a href={pdfNotaExceptuados}>Nota de exceptuados</a></p>
    </Form>
  );
};

export default LoginForm;
