import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoIPuestosTable = props => {
  const [ciuos, setCiuos] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SINIESTRALIDAD.CIUO88").then((response) => { setCiuos(response); })
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdPuesto', key: 'IdPuesto' },
        { title: 'Nombre', dataIndex: 'Nombre', key: 'Nombre' },
        { title: 'Ciuo', dataIndex: 'Ciuo', key: 'Ciuo',
          render: text => Utils.getIdDesc(text, ciuos) 
        },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdPuesto ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Puestos }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoIPuestosTable;