import Request from "../../../shared/helpers/request";

const EstablecimientosApi = {
  GetEmpresasByCuit: cuit => {
    if(sessionStorage['Establecimientos_' + cuit]){
      return Promise.resolve(JSON.parse(sessionStorage['Establecimientos_' + cuit]));
    }

    return Request
      .get('establecimientos/empresas/bycuit/' + cuit)
      .then((response) => {
        sessionStorage.setItem('Establecimientos_' + cuit, JSON.stringify(response.data));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Get: codigo => {
    return Request
      .get('establecimientos/' + codigo)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetEstablecimientosUsuario: data => {
    return Request
      .get('establecimientos/byusuario', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

}

export default EstablecimientosApi;