import React from "react";
import { Card, Space, Table } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import parser from "../../../../../shared/helpers/parser";
import NominaExamenesMedicosTable from "./actividades/NominaExamenesMedicosTable";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import Utils from "../../../../../shared/helpers/utils";

const NominaActividadesTable = props => {
  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdActividad', key: 'IdActividad' },
        //{ title: 'Id Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa' },
        { title: 'Puesto Declarado', dataIndex: 'IdPuestoDeclarado', key: 'IdPuestoDeclarado',
          render: text => Utils.getIdDesc(text, props.puestos)
        },
        { title: 'Sector Declarado', dataIndex: 'IdSectorDeclarado', key: 'IdSectorDeclarado',
          render: text => Utils.getIdDesc(text, props.sectores)
        },
        { title: 'Permanente', dataIndex: 'Permanente', key: 'Permanente', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        { title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada',
          render: text => Utils.getIdDesc(text, props.sustancias)
        },
        { title: 'Fecha Inicio Exposicion', dataIndex: 'FechaInicioExposicion', key: 'FechaInicioExposicion', render: text => <span>{ parser.date(text) }</span> },
        { title: 'FechaFinExposicion', dataIndex: 'FechaFinExposicion', key: 'FechaFinExposicion', render: text => <span>{ parser.date(text) }</span> },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              { !record.IdActividad ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' }
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Actividades }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      expandable={ {
        expandedRowRender: record => <Card>
          <Card title="Examenes Medicos" type="inner" style={ { marginBottom: 16 } }>
            { record.ExamenesMedicos.length > 0
              ? <NominaExamenesMedicosTable ExamenesMedicos={ record.ExamenesMedicos }/>
              : '' }
          </Card>
        </Card>,
      } }
    />
  );
}

export default NominaActividadesTable;