import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Tabs, List, Space, Input, Spin, Card, Table, notification } from "antd";
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import UsuariosApi from "../../api/UsuariosApi";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import EditButton from "../../../../shared/components/editButton";
import DeleteButton from "../../../../shared/components/deleteButton";
import Usuario_EstablecimientoApi from "../../../usuario_establecimiento/api/Usuario_EstablecimientoApi";
import PresentacionesApi from "../../../presentaciones/api/PresentacionesApi";
import AuthApi from "../../../auth/api/AuthApi";

const UsuariosList = () => {
  const history = useHistory();
  const [usuarios, setUsuarios] = useState([]);
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [working, setWorking] = useState(false);
  let tipoUsuario = AuthenticationHelper.getTipoUsuario();
  let idUsuario = AuthenticationHelper.getId();

  useEffect(() => {
    loadUsuarios();
  }, []);

  const loadUsuarios = () => {
    let usuarioTabla = new Object();
    let usuariosCompletos = [];
    setWorking(true);
    if (tipoUsuario == "Administrador") {
      UsuariosApi.GetAll()
        .then((response) => {
          var totalUsuarios = response.filter(usuario => usuario.Id !== 1 && usuario.Id != idUsuario);;
          setWorking(false);
          //setUsuarios(response.filter(x => x.Id != idUsuario));
          //setFilteredUsuarios(response.filter(x => x.Id != idUsuario));
          PresentacionesApi.GetAll()
            .then(response1 => {
              UsuariosApi.GetTipoUsuarios()
                .then(response2 => {
                  totalUsuarios.forEach(usuarioFor => {
                    usuarioTabla = usuarioFor;
                    response1.forEach(presentacionFor => {
                      if (usuarioFor.Id_Presentacion == presentacionFor.Id) {
                        let Cuit = presentacionFor.Cuit;
                        usuarioTabla = {
                          ...usuarioTabla,
                          Cuit
                        }
                      }
                      if(usuarioTabla.Cuit==undefined){
                        let Cuit = "Todas";
                        usuarioTabla = {
                          ...usuarioTabla,
                          Cuit
                        }
                      } 
                    });
                    response2.forEach(tipoUsuarioFor => {

                      if (usuarioFor.Id_TipoUsuario == tipoUsuarioFor.Id) {
                        usuarioTabla = {
                          ...tipoUsuarioFor,
                          ...usuarioTabla
                        }
                      }
                    });
                    usuariosCompletos.push(usuarioTabla);
                  });
                  setUsuarios(usuariosCompletos);
                  setFilteredUsuarios(usuariosCompletos);
                });
            });
        })
        .catch((error) => { setWorking(false); });
    } else {
      let idPresentacion = AuthenticationHelper.getIdPresentacionUsuario().toString();
      UsuariosApi.GetByPresentacion(idPresentacion)
        .then((response) => {
          var totalUsuarios = response.filter(usuario => usuario.Id_TipoUsuario !== 2);
          setWorking(false);
          //setUsuarios(response.filter(x => x.Id != idUsuario));
          //setFilteredUsuarios(response.filter(x => x.Id != idUsuario));
          PresentacionesApi.GetAll()
            .then(response1 => {
              UsuariosApi.GetTipoUsuarios()
                .then(response2 => {
                  totalUsuarios.forEach(usuarioFor => {
                    usuarioTabla = usuarioFor;
                    response1.forEach(presentacionFor => {
                      if (usuarioFor.Id_Presentacion == presentacionFor.Id) {
                        let Cuit = presentacionFor.Cuit;
                        usuarioTabla = {
                          ...usuarioTabla,
                          Cuit
                        }
                      } 
                      if(usuarioTabla.Cuit==undefined){
                        let Cuit = "Todas";
                        usuarioTabla = {
                          ...usuarioTabla,
                          Cuit
                        }
                      } 
                    });
                    response2.forEach(tipoUsuarioFor => {

                      if (usuarioFor.Id_TipoUsuario == tipoUsuarioFor.Id) {
                        usuarioTabla = {
                          ...tipoUsuarioFor,
                          ...usuarioTabla
                        }
                      }
                    });
                    usuariosCompletos.push(usuarioTabla);
                  });
                  setUsuarios(usuariosCompletos);
                  setFilteredUsuarios(usuariosCompletos);
                });
            });
        })
        .catch((error) => { setWorking(false); });
    }
  }

  const cargarDatosUsuarioEdit = (usuario) => {
    AuthenticationHelper.storeIdTipoUsuario(usuario.Id_TipoUsuario);
    Usuario_EstablecimientoApi.GetIdsEstablecimientosUsuario(usuario.Id).then(response1 => {
      AuthApi.Decrypt(usuario.Clave).then(response2 => {
        if (usuario.Id_TipoUsuario !== 1) {
          PresentacionesApi.GetTipoPresentacion(usuario.Id_Presentacion).then(response => { AuthenticationHelper.storeIdTipoPresentacion(response); });
          PresentacionesApi.GetPresentacion(usuario.Id_Presentacion).then(response => { AuthenticationHelper.storePresentacionSeleccionada(response); });
        }
        history.push({ pathname: 'usuarios/update_' + usuario?.Id, usuario: usuario, claveUsuario: response2.password, establecimientos: response1 });
      });
    });

  }

  const handleSearch = e => {
    if (e.target.value.length > 0) {
      setFilteredUsuarios(usuarios.filter(x => x.Id.toString().indexOf(e.target.value) > -1 || x.Correo.indexOf(e.target.value) > -1));
    }
    else {
      setFilteredUsuarios(usuarios);
    }
  }

  const eliminarUsuario = usuario => {
    setWorking(true);
    setFilteredUsuarios([...filteredUsuarios.filter(item => (item.Id !== usuario.Id))]);

    Usuario_EstablecimientoApi.Delete(usuario.Id)
      .then((response) => {
        setWorking(false);
      })
      .catch((error) => { setWorking(false); });

    setWorking(true);
    UsuariosApi.Delete(usuario.Id)
      .then((response) => {
        setWorking(false);
        notification.success({ message: 'Exito', description: 'Usuario ' + usuario.Id + ' eliminado', placement: 'bottomRight' });
      })
      .catch((error) => { setWorking(false); });
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title="Usuarios"
        extra={[
          <Button key="1" type="primary" icon={<PlusOutlined />}
            onClick={() => history.push(history.location.pathname + '/new')}
          >
            Nuevo
          </Button>,
        ]}
      />
      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>
          <Card>
            <Input
              allowClear
              placeholder="Buscar"
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              style={{ marginBottom: 8 }}
            />

            <Table
              style={{ cursor: 'pointer' }}
              size="small"
              columns={[
                { title: 'Apellido', dataIndex: 'Apellido', key: 'Apellido' },
                { title: 'Nombre', dataIndex: 'Nombre', key: 'Nombre' },
                { title: 'DNI', dataIndex: 'Dni', key: 'Dni' },
                { title: 'Correo', dataIndex: 'Correo', key: 'Correo' },
                { title: 'Cuit de Presentacion', dataIndex: 'Cuit', key: 'Cuit' },
                { title: 'Tipo de Usuario', dataIndex: 'Tipo', key: 'Tipo' },
                {
                  title: '', dataIndex: 'Actions', key: 'Actions',
                  render: (text, record) => {
                    return <Space size="small">
                      <EditButton onClick={() => {
                        cargarDatosUsuarioEdit(record);
                      }} />
                      <DeleteButton onConfirm={() => eliminarUsuario(record)} />
                    </Space>
                  }
                }
              ]}
              dataSource={filteredUsuarios}
              rowKey={(record) => record.Id}
              pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
            />

            {/*<List
                  style={ { overflow: 'auto', maxHeight: 600 } }
                  size="small"
                  bordered
                  dataSource={ filteredPresentaciones }
                  renderItem={item => <List.Item 
                    style={ { cursor: 'pointer' } }
                    onClick={ () => history.push(history.location.pathname + '/view/' + item.Id + '/' + item.Cuit) }>
                    <List.Item.Meta
                      title={ <span>Id: <b>{ item.Id }</b></span> }
                      description={ <span>Cuit: <b>{ item.Cuit }</b></span> }
                    />
                    <div>{ parser.datetime(item.FechaConsolidacion) !== '' 
                      ? <Tooltip title="Consolidada" placement="left"><CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                      : '' 
                    }</div>
                  </List.Item>}
                  />*/}
          </Card>
        </Spin>
      </div>
    </div>
  );

};

export default UsuariosList;