import Request from "../../../shared/helpers/request";

const PresentacionesApi = {
  GetAll: () => {
    return Request
      .get('presentaciones')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetPresentacion: idUsuario => {
    return Request
      .get('presentaciones/get/' + idUsuario)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetPresentacionesUsuario: idUsuario => {
    return Request
      .get('presentaciones/' + idUsuario)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetByCuit: (cuit) => {
    return Request
      .get('presentaciones/bycuit/' + cuit)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Get: (id, cuit) => {
    return Request
      .get('presentaciones/' + id + '/' + cuit)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetCuitPresentacion: (id) => {
    return Request
      .get('presentaciones/getcuit/' + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetTipoPresentacion: (id) => {
    return Request
      .get('presentaciones/tipo/' + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Iniciar: (empleador, idTipoPresentacion, data) => {
    return Request
      .post('presentaciones/iniciar/'+ empleador + '/' + idTipoPresentacion, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Consolidar: data => {
    return Request
      .post('presentaciones/consolidar', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Cancelar: data => {
    return Request
      .post('presentaciones/cancelar', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetTiposPresentacion: () => {
    return Request
      .get('presentaciones/tipopresentacion')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  Eliminar: id => {
    return Request
      .delete('presentaciones/' + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GuardarHistorial: (data) => {
    return Request.post(
      'presentaciones/historial/guardar', data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
  },

  GetHistorialPresentacion: (idPresentacion) => {
    return Request
      .get('presentaciones/historial/obtener/' + idPresentacion)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  GetHistorial: (id) => {
    return Request
      .get('presentaciones/historial/' + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  
}

export default PresentacionesApi;