import React from "react";
import { Space, Table } from "antd";
import parser from "../../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../../shared/components/deleteButton";
import EditButton from "../../../../../../shared/components/editButton";

const AnexoVResponsablesDeUsoTable = props => {
  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdResponsableDeUso', key: 'IdResponsableDeUso' },
        //{ title: 'Equipo Radiologico', dataIndex: 'IdEquipoRadiologico', key: 'IdEquipoRadiologico'},
        { title: 'Cuil', dataIndex: 'Cuil', key: 'Cuil' },
        { title: 'Autorizacion Individual', dataIndex: 'AutorizacionIndividual', key: 'AutorizacionIndividual' },
        { title: 'Nro Permiso Habilitante', dataIndex: 'NroPermisoHabilitante', key: 'NroPermisoHabilitante' },
        { title: 'Matricula Profesional', dataIndex: 'MatriculaProfesional', key: 'MatriculaProfesional' },
        { title: 'Fecha Expedicion', dataIndex: 'FechaExpedicion', key: 'FechaExpedicion', render: text => <span>{ parser.date(text) }</span> },
        { title: 'Fecha Vencimiento', dataIndex: 'FechaVencimiento', key: 'FechaVencimiento', render: text => <span>{ parser.date(text) }</span> },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdResponsableDeUso ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.ResponsablesDeUso }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVResponsablesDeUsoTable;