import React from "react";
import { Layout } from "antd";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import AppBreadcrumb from "./breadcrumb/AppBreadcrumb";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './AppContent.scss';
import ReferenciasPage from "../../../referencias/ReferenciasPage";
import PresentacionesPage from "../../../presentaciones/PresentacionesPage";
import AnexoIPage from "../../../anexosI/AnexosIPage";
import AnexoVPage from "../../../anexosV/AnexosVPage";
import NominasPage from "../../../nominas/NominasPage";
import UsuariosPage from "../../../usuarios/UsuariosPage";
import AyudaPage from "../../../ayuda/AyudaPage";
import LoginContainer from "../../../login/LoginContainer";

const { Content } = Layout;

const AppContent = ({location}) => {
  const history = useHistory();

  if(location.pathname === '/'){
    history.push('/presentaciones');
  }

  return (
    <Content>
      <div style={ { padding: 16 } }>
        
        <AppBreadcrumb />

        <TransitionGroup className="transition-group">
          <CSSTransition
            key={ location.key }
            classNames="slide"
            timeout={ 1000 }
          >
            <section className="route-section">
              <Switch location={ location }>
                {/*<Route path="/referencias" component={ AuthorizationHelper.requireAuth(ReferenciasPage) }/>*/}
                <Route path="/login" component={ LoginContainer }/>
                <Route path="/referencias" component={ ReferenciasPage }/>
                <Route path="/presentaciones" component={ PresentacionesPage }/>
                <Route path="/anexoi" component={ AnexoIPage }/>
                <Route path="/anexov" component={ AnexoVPage }/>
                <Route path="/nomina" component={ NominasPage }/>
                <Route path="/auth/ticket/usuarios" component={ UsuariosPage }/>
                <Route path="/ayuda" component={ AyudaPage }/>
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>

      </div>
    </Content>
  );
}

export default withRouter(AppContent);