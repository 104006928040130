import React, { useEffect, useState } from "react";
import { Card, Space, Table, Input } from "antd";
import NominaActividadesTable from "./trabajadores/NominaActividadesTable";
import parser from "../../../../shared/helpers/parser";
import DeleteButton from "../../../../shared/components/deleteButton";
import EditButton from "../../../../shared/components/editButton";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import Utils from "../../../../shared/helpers/utils";
import { SearchOutlined } from '@ant-design/icons';


const NominaTrabajadoresTable = props => {
  const [filteredTrabajadores, setFilteredTrabajadores] = useState(props.Trabajadores);
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  var trabajadores = props.Trabajadores;

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
  }, []);

  useEffect(() => {
    setFilteredTrabajadores(props.Trabajadores);
  }, [props.Trabajadores]);

  const getEstablecimiento = id => {
    return props.anexoi.EstablecimientosDeclarados[props.anexoi.EstablecimientosDeclarados.findIndex(x => x.IdEstablecimientoEmpresa == id)]
  }

  const handleSearch = e => {
    if (e.target.value.length > 0) {
      setFilteredTrabajadores(trabajadores.filter(x => x.IdTrabajador.toString().indexOf(e.target.value) > -1 || x.Cuil.toString().indexOf(e.target.value) > -1));
    }
    else {
      setFilteredTrabajadores(props.Trabajadores);
    }
  }

  return (
    <Card>
      <Input
        allowClear
        placeholder="Buscar"
        onChange={handleSearch}
        suffix={<SearchOutlined />}
        style={{ marginBottom: 8 }}
      />
      <Table
        style={{ overflow: 'auto' }}
        size="small"
        columns={[
          { title: 'Id', dataIndex: 'IdTrabajador', key: 'IdTrabajador' },
          {
            title: 'Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa',
            render: text => Utils.getIdDesc(text, establecimientosEmpresa)
          },
          { title: 'Cuil', dataIndex: 'Cuil', key: 'Cuil' },
          { title: 'Fecha Ingreso', dataIndex: 'FechaIngreso', key: 'FechaIngreso', render: text => <span>{parser.date(text)}</span> },
          props.formMode ? {
            title: '', dataIndex: 'Actions', key: 'Actions',
            render: (text, record) => {
              return <Space size="small">
                {
                  props.puedeEditar ?
                  <>
                    <EditButton onClick={() => props.onEdit(record)} />
                    {!record.IdTrabajador ? <DeleteButton onConfirm={() => props.onRemove(record)} /> : ''}
                  </>
                  :null
                }
              </Space>
            }
          } : {},
        ]}
        dataSource={filteredTrabajadores}
        rowKey={(record) => record.Id}
        pagination={{ hideOnSinglePage: true, pageSize: 30, showSizeChanger: false }}
        expandable={{
          expandedRowRender: record => <Card>
            <Card title="Actividades" type="inner" style={{ marginBottom: 16 }}>
              {record.Actividades.length > 0
                ? <NominaActividadesTable
                  Actividades={record.Actividades}
                  puestos={getEstablecimiento(record.IdEstablecimientoEmpresa)?.Puestos}
                  sectores={getEstablecimiento(record.IdEstablecimientoEmpresa)?.Sectores}
                  sustancias={props.anexov.Sustancias}
                />
                : ''}
            </Card>
          </Card>,
        }}
      />
    </Card>

  );
}

export default NominaTrabajadoresTable;