import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Popover, Tooltip, Divider, Space, notification, Avatar } from 'antd';
import { useHistory } from "react-router";
import { UserOutlined } from '@ant-design/icons';
import './AppHeader.scss';
import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import AuthApi from '../../../auth/api/AuthApi';
import { AppConfig } from '../../../../shared/config/GlobalConfig';
import UsuariosApi from '../../../usuarios/api/UsuariosApi';
import CambiarClaveModal from '../../../../shared/components/cambiarClaveModal';

const { Header } = Layout;

const AppHeader = () => {
  let history = useHistory();
  const [working, setWorking] = useState(false);
  const [tipoUsuario, setTipoUsuario] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    UsuariosApi.TipoUsuario(AuthenticationHelper.getId()).then(response => setTipoUsuario(response));
  }, []);

  const logout = () => {
    AuthenticationHelper.logout(() => history.push("/login"));
  };

  const forceTicket = () => {
    setWorking(true);
    AuthApi.ForceTicket("WSE")
      .then(response => {
        setWorking(false);
        if (response !== '') {
          notification.success({ message: 'Éxito', description: 'Ticket solicitado correctamente', placement: 'bottomRight' });
        } else {
          notification.error({ message: 'Error', description: '¡Error al solicitar ticket! Cerrar la página y abrir nuevamente.', placement: 'bottomRight' });
        }
      })
      .catch(() => setWorking(false));
  };

  return (
    <>
      <Header
        className="app-layout-background"
        style={{
          padding: '0 15px',
          backgroundColor: '#fff',
        }}
      >
        <Row align="middle">
          {/* Logo y Nombre del Sitio */}
          <Col xs={12} sm={12} md={12} lg={12}>
          <div>
            <h2 style={{ fontWeight: 100, margin: 0, fontSize: '1.8em' }} className="lead letraCaja">{AppConfig.siteName}</h2>
          </div>
          </Col>

          {/* Menú de Usuario */}
          <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
            <Space size="large">
              {window.location.href.indexOf('test') > -1 || window.location.href.indexOf('localhost') > -1
              ? <h2 style={{ fontWeight: 100, margin: 0 }}></h2>
              : ''}
              <Popover
                placement="bottomRight"
                content={
                  <div>
                    {tipoUsuario !== "Operador" && (
                      <>
                        <Button
                          style={{ textAlign: 'left', width: '100%' }}
                          type="text"
                          onClick={forceTicket}
                          loading={working}
                        >
                          Solicitar Nuevo Ticket
                        </Button>
                        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                      </>
                    )}
                    <Button
                      style={{ textAlign: 'left', width: '100%' }}
                      type="text"
                      onClick={() => setShowModal(true)}
                    >
                      Cambiar clave
                    </Button>
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    <Button
                      style={{ textAlign: 'left', width: '100%' }}
                      type="text"
                      onClick={logout}
                    >
                      Salir
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <Tooltip title="Menú" placement="left">
                  <Avatar size="large" style={{ backgroundColor: '#f15e21', cursor: 'pointer', marginBottom: 10}} icon={<UserOutlined />} />
                </Tooltip>
              </Popover>
            </Space>
          </Col>
        </Row>

        {/* Modal para Cambiar Clave */}
        {showModal && (
          <CambiarClaveModal visible={showModal} onCancel={() => setShowModal(false)} />
        )}
      </Header>
    </>
  );
};

export default AppHeader;
