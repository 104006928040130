import React, { useEffect, useState } from "react";
import { Card, Space, Table } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import parser from "../../../../../shared/helpers/parser";
import AnexoVResponsablesDeUsoTable from "./equipos_radiologicos/AnexoVResponsablesDeUsoTable";
import AnexoVResponsablesDeInstalacionTable from "./equipos_radiologicos/AnexoVResponsablesDeInstalacionTable";
import AnexoVEncargadosDeProteccionRadiologicaTable from "./equipos_radiologicos/AnexoVEncargadosDeProteccionRadiologicaTable";
import AnexoVDosimetriaView from "./equipos_radiologicos/AnexoVDosimetriaView";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoVEquiposRadiologicosTable = props => {
  const [tiposEquipo, setTiposEquipo] = useState([]);
  const [caracteristicasEquipo, setCaracteristicasEquipo] = useState([]);
  const [tiposRadiacion, setTiposRadiacion] = useState([]);
  const [tiposFuente, setTiposFuente] = useState([]);
  const [metodosProcesamiento, setMetodosProcesamiento] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TTIPOS_EQUIPOS").then(response => { setTiposEquipo(response); });
    ReferenciasApi.GetTabla("SVCC.TCARACTERISTICAS_EQUIPOS").then(response => { setCaracteristicasEquipo(response); });
    ReferenciasApi.GetTabla("SVCC.TTIPOS_RADIACIONES").then(response => { setTiposRadiacion(response); });
    ReferenciasApi.GetTabla("SVCC.TTIPOS_FUENTES_EQUIPOS").then(response => { setTiposFuente(response); });
    ReferenciasApi.GetTabla("SVCC.TMETODOS_PROCESADOS_IMAGENES").then(response => { setMetodosProcesamiento(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdEquipoRadiologico', key: 'IdEquipoRadiologico' },
        //{ title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        { title: 'Marca', dataIndex: 'Marca', key: 'Marca' },
        { title: 'Modelo', dataIndex: 'Modelo', key: 'Modelo' },
        { title: 'Tipo Equipo', dataIndex: 'IdTipoEquipo', key: 'IdTipoEquipo',
          render: text => Utils.getIdDesc(text, tiposEquipo)
        },
        { title: 'Caracteristica Equipo', dataIndex: 'IdCaracteristicaEquipo', key: 'IdCaracteristicaEquipo',
          render: text => Utils.getIdDesc(text, caracteristicasEquipo)
        },
        { title: 'Tipo Radiacion', dataIndex: 'IdTipoRadiacion', key: 'IdTipoRadiacion',
          render: text => Utils.getIdDesc(text, tiposRadiacion)
        },
        { title: 'Actividad', dataIndex: 'Actividad', key: 'Actividad' },
        { title: 'Radioisotopo Fuente', dataIndex: 'RadioisotopoFuente', key: 'RadioisotopoFuente' },
        { title: 'Tipo Fuente', dataIndex: 'IdTipoFuente', key: 'IdTipoFuente',
          render: text => Utils.getIdDesc(text, tiposFuente)
        },
        { title: 'Potencia Equipo KVP', dataIndex: 'PotenciaEquipoKVP', key: 'PotenciaEquipoKVP' },
        { title: 'Potencia Equipo mAp', dataIndex: 'PotenciaEquipomAp', key: 'PotenciaEquipomAp' },
        { title: 'Potencia Equipo MeV', dataIndex: 'PotenciaEquipoMeV', key: 'PotenciaEquipoMeV' },
        { title: 'Procesamiento Imagenes', dataIndex: 'ProcesamientoImagenes', key: 'ProcesamientoImagenes', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        { title: 'Metodo Procesamiento', dataIndex: 'MetodoProcesamiento', key: 'MetodoProcesamiento',
          render: text => Utils.getIdDesc(text, metodosProcesamiento)
        },
        { title: 'Observaciones', dataIndex: 'Observaciones', key: 'Observaciones' },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdEquipoRadiologico ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.EquiposRadiologicos }
      rowKey={(record) => record.Id}
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      expandable={ {
        expandedRowRender: record => <Card>
          <Card title="Dosimetria" type="inner" style={ { marginBottom: 16 } }>
            <AnexoVDosimetriaView
              Dosimetria={ record.Dosimetria }
            />
          </Card>
          <Card title="Responsables de Uso" type="inner" style={ { marginBottom: 16 } }>
            { record.ResponsablesDeUso.length > 0
              ? <AnexoVResponsablesDeUsoTable ResponsablesDeUso={ record.ResponsablesDeUso }/>
              : '' }
          </Card>
          <Card title="Responsables de Instalacion" type="inner" style={ { marginBottom: 16 } }>
            { record.ResponsablesDeInstalacion.length > 0
              ? <AnexoVResponsablesDeInstalacionTable ResponsablesDeInstalacion={ record.ResponsablesDeInstalacion }/>
              : '' }
          </Card>
          <Card title="Encargados de Proteccion Radiologica" type="inner" style={ { marginBottom: 16 } }>
            { record.EncargadosDeProteccionRadiologica.length > 0
              ? <AnexoVEncargadosDeProteccionRadiologicaTable EncargadosDeProteccionRadiologica={ record.EncargadosDeProteccionRadiologica }/>
              : '' }
          </Card>
        </Card>
      } }
    />
  );
}

export default AnexoVEquiposRadiologicosTable;