import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Collapse, Modal, Tooltip, DatePicker } from "antd";
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import Utils from "../../../../shared/helpers/utils";
import NominaActividadesTable from "../view/trabajadores/NominaActividadesTable";
import NominaActividadModal from "./trabajadores/NominaActividadModal";
import moment from "moment";
import EstablecimientoEmpresaSelectFormItem from '../../../../shared/components/establecimientoEmpresaSelectFormItem';
import CuitFormItem from '../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;
const { Panel } = Collapse;

const NominaTrabajadorModal = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [actividades, setActividades] = useState([]);
  const [showActividadModal, setShowActividadModal] = useState(false);
  const [selectedActividad, setSelectedActividad] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    loadEstablecimientosEmpresa();
  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setActividades(props.trabajador ? props.trabajador.Actividades : []);
    }
  }, [props.visible]);

  const loadEstablecimientosEmpresa = () => {
    setWorking(true);
    EstablecimientosApi.GetEmpresasByCuit(props.cuit)
      .then((response) => {
        setWorking(false);
        setEstablecimientosEmpresa(response);
      })
      .catch((error) => { setWorking(false); });
  }

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.trabajador ? props.trabajador.Hash : Utils.generateHash(),
          ...values,
          Actividades: actividades,
        });
      });
  }

  const getEstablecimiento = () => {
    let id = form.getFieldValue('IdEstablecimientoEmpresa') ? form.getFieldValue('IdEstablecimientoEmpresa') : props.trabajador?.IdEstablecimientoEmpresa;
    return props.anexoi?.EstablecimientosDeclarados[props.anexoi?.EstablecimientosDeclarados.findIndex(x => x.IdEstablecimientoEmpresa == id)]
  }

  const filterEstablecimientos = () => {
    return establecimientosEmpresa.filter(x => props.anexoi?.EstablecimientosDeclarados.find(dec => dec.IdEstablecimientoEmpresa == x.Codigo));
  }

  return (
    <Modal
      title={`${props.trabajador ? 'Editar' : 'Agregar'} Trabajador`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{
            ...props.trabajador,
            FechaIngreso: props.trabajador ? moment(props.trabajador.FechaIngreso) : undefined,
          }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Trabajador" key="1">
              {props.trabajador?.IdTrabajador ? <FormItem name="IdTrabajador" label="Trabajador" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.trabajador
                ? <FormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa" rules={FormConfig.DefaultRules}>
                  <Input disabled />
                </FormItem>
                : <EstablecimientoEmpresaSelectFormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa"
                  rules={FormConfig.DefaultRules}
                  establecimientosEmpresa={filterEstablecimientos()}
                />}

              <CuitFormItem name="Cuil" label="Cuil" rules={[...FormConfig.DefaultRules]} />

              <FormItem name="FechaIngreso" label="Fecha Ingreso" rules={FormConfig.DefaultRules}>
                <DatePicker style={{ width: '100%' }} format={"DD/MM/YYYY"} />
              </FormItem>
            </Panel>

            <Panel header="Actividades" key="2" forceRender={true}
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedActividad(undefined);
                  setShowActividadModal(true);
                }}
              /></Tooltip>}
            >
              {actividades.length > 0
                ? <NominaActividadesTable formMode
                  Actividades={actividades}
                  puestos={getEstablecimiento()?.Puestos}
                  sectores={getEstablecimiento()?.Sectores}
                  sustancias={props.anexov.Sustancias}
                  onRemove={record => { setActividades([...actividades.filter(item => (item.Hash !== record.Hash))]); }}
                  onEdit={record => {
                    setSelectedActividad(record);
                    setShowActividadModal(true);
                  }}
                />
                : ''}
            </Panel>
          </Collapse>

        </Form>

        {
          showActividadModal ?
            <>
              <NominaActividadModal
                visible={showActividadModal}
                actividad={selectedActividad}
                IdEstablecimientoEmpresa={getEstablecimiento()?.IdEstablecimientoEmpresa}
                puestos={getEstablecimiento()?.Puestos}
                sectores={getEstablecimiento()?.Sectores}
                sustancias={props.anexov?.Sustancias}
                onOk={actividad => {
                  let arr = [...actividades];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === actividad.Hash : x.IdActividad === actividad.IdActividad);
                  if (idx !== -1) { arr[idx] = actividad; }
                  else { arr.push(actividad); }
                  setActividades([...arr]);
                  setShowActividadModal(false);
                }}
                onCancel={() => setShowActividadModal(false)}
              />
            </> : null
        }

        <CampoObligatorio/>
        
      </Spin>
    </Modal>
  );
}

export default NominaTrabajadorModal;