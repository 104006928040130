import axios from 'axios';
import AuthenticationHelper from './authenticationHelper';
import { notification,message } from 'antd';

//axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;
// axios.defaults.baseURL = `http://localhost:5000/api/`; //(Esto es para cuando se ejecuta el backend localmente)
// axios.defaults.baseURL = `http://cancerigenos.dev.cajapop.org/api/`; //Direccion del servidor de prueba
axios.defaults.baseURL = `http://cancerigenos.cajapopular.gob.ar/api/`; //Direccion del servidor de produccion

axios.interceptors.request.use(config => {
  //config.headers = AuthenticationHelper.isJwtTokenStored() ? { ...config.headers, Authorization: `Bearer ${AuthenticationHelper.getJwtToken()}` } : config.headers;
  config.headers = AuthenticationHelper.isSRTTicketStored() ? { ...config.headers, "x-ticket": AuthenticationHelper.getSRTTicket() } : config.headers;
  return config;
});

axios.interceptors.response.use(
  response => {
    if(response.data.ValidationErrors){
      for (const validationType in response.data.ValidationErrors) {
        const validationMessage = response.data.ValidationErrors[validationType]; 
        message.error("[Validacion Servidor] " + validationMessage);
      }
    }
    return response;
  },
  error => {
    if(error.response){console.debug(error.response);
      let message;
      try {
        message = typeof error.response.data !== 'object' ? error.response.data
          : error.response.data.error ? error.response.data.error 
            : error.response.data.ExceptionMessage ? error.response.data.ExceptionMessage
              : error.response.data.Message ? error.response.data.Message
                : 'Ocurrio un error...';
      } catch (e) {
        message = 'Ocurrio un error...';
      }

      message = message.length > 300 ? message.substring(0, 300) + '...' : message;

      switch(error.response.status){
        case 401:
          AuthenticationHelper.logout(() => {
            window.location.replace("/login");
          });
          notification.error({ message: 'Error', description: message, placement: 'bottomRight' });
          break;
        case 404:
          message = "[Endpoint No encontrado] " + error.response.config.baseURL + error.response.config.url;
          notification.error({ message: 'Error', description: message, placement: 'bottomRight' });
          break;
        default: 
          notification.error({ message: 'Error', description: message, placement: 'bottomRight' });
      }
      return Promise.reject(error);
    }
    notification.error({ message: 'Error', description: error, placement: 'bottomRight' });
    return Promise.reject(error);
  }
);

export default class Request {
  static get(path, callback) {
    return axios.get(path, { callback });
  }

  static post(path, data = {}, callback) {
    return axios.post(path, data, { callback });
  }

  static postMultipart(path, data = {}, callback) {
    return axios.post(path, data, { callback, headers: {'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static put(path, data = {}, callback) {
    return axios.put(path, data, { callback });
  }

  static delete(path, callback) {
    return axios.delete(path, { callback });
  }

  static delete(path, data) {
    return axios.delete(path, { data });
  }

  static download(path){
    return axios({ url: path, method: 'GET', responseType: 'blob'});
  }

  static upload(path, data = {}, notifier = () => {}){
    return new Promise(function (resolve, reject) {
      let formData = new FormData();
      formData.append('file', data);

      const req = new XMLHttpRequest();
      req.open('POST', axios.defaults.baseURL + path);

      if(AuthenticationHelper.isSRTTicketStored()){
        req.setRequestHeader('x-ticket', AuthenticationHelper.getSRTTicket());
      }

      req.addEventListener('load', e => {
        notifier(100);
        if (req.status >= 200 && req.status <= 299) {
          //callback(JSON.parse(req.response));
          try
          {
            resolve(JSON.parse(req.response));
          }
          catch(ExceptionMessage)
          {
            notification.error({ message: 'Error', description: "¡Formato incorrecto!", placement: 'bottomRight' });
          }
          resolve(JSON.parse(req.response));
        } else {
          //resolve(callback(req));
          notification.error({ message: 'Error', description: JSON.parse(req.response).error, placement: 'bottomRight' });
          reject(req);
        }
      }, false);

      req.addEventListener('error', e => {
        //callback(e);
        notification.error({ message: 'Error', description: e, placement: 'bottomRight' });
        reject(e);
      }, false);

      req.upload.addEventListener('progress', e => {
        let progress = 0;
        if (e.total !== 0) { progress = parseInt((e.loaded / e.total) * 100, 10); }
        notifier(progress);
      }, false);

      req.send(formData);
    });
  }
}
